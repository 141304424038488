document.addEventListener('DOMContentLoaded', function () {
	objectFitImages();
	setUA();
	toggleGnav();
	smoothScroll();
})
window.addEventListener('load', function () {
	$('body').addClass('is-load');
	AOS.init({
		once: true,
		duration: 600,
	});
})
window.addEventListener('scroll', function () {
});


function setUA() {
	//ua判定
	var ua = UAParser()
	var HTML = document.getElementsByTagName('html')[0];
	var uaString = {
		browserName: ua.browser.name.toLowerCase().replace(' ', '-'),
		browserVersion: ua.browser.major,
		browserEngine: ua.engine.name.toLowerCase().replace(' ', '-'),
		osName: ua.os.name.toLowerCase().replace(' ', '-'),
		type: (typeof ua.device.type !== 'undefined') ? ua.device.type.toLowerCase().replace(' ', '-') : 'laptop'
	}
	HTML.dataset.browser = uaString.browserName
	HTML.dataset.browserversion = uaString.browserVersion
	HTML.dataset.browserengine = uaString.browserEngine
	HTML.dataset.os = uaString.osName
	HTML.dataset.type = uaString.type
}
function smoothScroll() {
	$('a[href^="#"]').click(function () {
		// スクロールの速度
		var speed = 500; // ミリ秒
		// アンカーの値取得
		var href = $(this).attr("href");
		// 移動先を取得
		var target = $(href == "#" || href == "" ? 'html' : href);
		// 移動先を数値で取得
		var position = target.offset().top;
		// スムーススクロール
		$('body,html').animate({ scrollTop: position }, speed, 'swing');
		return false;
	});
}

//ハンバーガーメニュー
function toggleGnav() {
	$('.js-menu').click(function () {
		$('.js-gnav').toggleClass('is-open')
		return false;
	})
	$('.js-close').click(function () {
		$('.js-gnav').toggleClass('is-open')
		return false;
	})
}

//Adobeフォント読み込み
(function(d) {
	var config = {
			kitId: 'luy2dde',
			scriptTimeout: 3000,
			async: true
		},
		h = d.documentElement,
		t = setTimeout(function() {
			h.className = h.className.replace(/\bwf-loading\b/g, "") + " wf-inactive";
		}, config.scriptTimeout),
		tk = d.createElement("script"),
		f = false,
		s = d.getElementsByTagName("script")[0],
		a;
	h.className += " wf-loading";
	tk.src = 'https://use.typekit.net/' + config.kitId + '.js';
	tk.async = true;
	tk.onload = tk.onreadystatechange = function() {
		a = this.readyState;
		if (f||a && a != "complete" && a != "loaded") return;
		f = true;
		clearTimeout(t);
		try {
			Typekit.load(config)
		} catch (e) {}
	};
	s.parentNode.insertBefore(tk, s)
})(document);